import React from 'react';
import { Container, Row, Col, Form } from "react-bootstrap"
import gql from "graphql-tag"
import { useQuery } from '@apollo/client';
import { Link } from "gatsby";
import { Logo, AreaGuideArrow } from '../components/icon';
import ReactMarkdown from "react-markdown/with-html";

const STATIC_POPULAR_SEARCH = gql`
query GetstaticPopularSearch{
    globalConfig {
        Popular_Search_Static_Common
        Popular_Search_Static_Sales
        Popular_Search_Static_Rentals
        Popular_Search_Static_Buy
        Popular_Search_Static_New_Homes
        Popular_Search_Static_Investor
        Popular_Search_Static_Manchester
    }
}
`;

function PopularSearchStatic(props) {
    var popularSearchtype = props.popularSearch
    const { loading, error, data } = useQuery(STATIC_POPULAR_SEARCH);
      if (loading) return (
        <div className="static-details gql-loading-screen d-none">
            <Container>
            <Logo />
            <div className="gql-loading">Loading ...</div>
            </Container>
        </div>);
            return (
            <>
            {popularSearchtype=='Popular_Search_Static_Common' ?
            <ReactMarkdown source={data?.globalConfig?.Popular_Search_Static_Common} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Popular_Search_Static_Sales' ?
            <ReactMarkdown source={data?.globalConfig?.Popular_Search_Static_Sales} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Popular_Search_Static_Rentals' ?
            <ReactMarkdown source={data?.globalConfig?.Popular_Search_Static_Rentals} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Popular_Search_Static_Buy' ?
            <ReactMarkdown source={data?.globalConfig?.Popular_Search_Static_Buy} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Popular_Search_Static_New_Homes' ?
            <ReactMarkdown source={data?.globalConfig?.Popular_Search_Static_New_Homes} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Popular_Search_Static_Investor' ?
            <ReactMarkdown source={data?.globalConfig?.Popular_Search_Static_Investor} escapeHtml={false}/>
            : ''
            }
            {popularSearchtype == 'Popular_Search_Static_Manchester' ?
            <ReactMarkdown source={data?.globalConfig?.Popular_Search_Static_Manchester} escapeHtml={false}/>
            : ''
            }
            </>
        )
}

export default PopularSearchStatic