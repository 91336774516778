import React, {useEffect, useState} from "react";
import whatsappDesktopbutton from "../../images/whatsappdesktopcta.svg";
//import whatsappDesktopbuttonsvg from "../../images/whatsapp-float.svg";
import TelegramDesktop from "../../images/telegramdesktopcta.svg";
import TelegramMobile from "../../images/telegramctamobile.svg";
import MobileWhatsAppIcon from "../../images/whatsappctamobile.svg";
import "./FloatingPoint.scss";
import { useLocation } from "@reach/router"

const FloatingPointSection = (props) => {
    const [render, setRender] = useState(false)
    let area;
    const url = typeof window !== "undefined" ? window.location.href : ""
    var contactofficepath = url.includes("contactoffice=manchester-property-investments")
    var newDevelopmentpath = url.includes("new-development-in-manchester")
    var newDevelopmentdamacpath = url.includes("/new-development-in-manchester/damac-tower-london/")
    //console.log("newDevelopmentpath",newDevelopmentpath)

    const location = useLocation();
    if (props.storagearea === "Manchester" || props.addresstown === "Manchester" || props.developmentTitle === "New homes for sale" || contactofficepath) {
        area = 'yes'
    }
    var whatsappPhoneNumber = props.whatsapplink && props.whatsapplink || area == 'yes' || newDevelopmentpath ? "+447450209485" : "+447833457485";
    var whatsappMsg = `I am interested in discussing about properties with Orlando Reid. I am inquiring from this page ${location.href} on the website`
    var whatsappMsg2 = `I am interested in discussing about properties with Orlando Reid. I am inquiring from this page ${location.href} on the website`
    if (props.whatsapplink && props.whatsapplink || area == 'yes' || newDevelopmentpath) {
        whatsappMsg = `Hi Will, ${whatsappMsg}`
    } else {
        whatsappMsg = `Hi Jack, ${whatsappMsg} `
    }
    
    if(newDevelopmentdamacpath){
        whatsappPhoneNumber = '+447833457485';
        whatsappMsg = `Hi Jack, ${whatsappMsg2} `
    }

    useEffect(()=>{
        if(!render){
            setRender(true)
        }
    })

    var whatsappurl = `https://wa.me/${whatsappPhoneNumber}?text=${whatsappMsg}`
    var telegramContact = (props?.telegramlink != 'yes' && area != 'yes' && !newDevelopmentpath) ? "+447833457485" : ""
    return (
        <div className="image-position">
            {/* <div>
             <a href={`tel:${phonenumberurl}`}>  
              <img src={phoneicon} className="floating-phone" /> 
             </a> 
            </div>  */}

            {/* {telegramContact &&
                <div className="floating-telegram">
                    <a
                        href={`https://telegram.me/${telegramContact}`}
                        target="_blank"

                    >
                        <img
                            loading="lazy"
                            src={TelegramDesktop}
                            alt="telegram"
                            title="telegram"
                            className="telegram-desktop-float"
                        />
                        <img src={TelegramMobile} alt="telegram" className="telegram-mobile-float" />

                    </a>
                </div>
            }

            {render &&
              <div className="whatsapp-btn">
                    <a href={whatsappurl} target="blank">
                        <img src={MobileWhatsAppIcon} alt="Whatsapp-tablet-button" className="floating-whatsapp" />
                        <img src={whatsappDesktopbutton} alt="Whatsapp-desktop-button" className="whatsapp-desktop-position" />
                    </a>
                </div>
            } */}
          
            {/* {newDevelopmentpath &&
             <div>
                <a href={`https://wa.me/+447450209485?text=${msg}`} target="blank">  
                <img src={whatsappDesktopbuttonsvg} alt="Whatsapp-desktop-button" className="whatsapp-desktop-position" />  
            </a>
            </div>
            } */}
        </div>
    );
}
export default FloatingPointSection;
