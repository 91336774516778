import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

const GETURL = ({ label, URL, Secondary_URL }) => {
  const data = useStaticQuery(graphql`
    query URLQuery {
      glstrapi {
        allMenus {
          Label
          Alias
          URL
          Secondary_URL
          main_parent {
            Label
            URL
          }
          sub_parent {
            Label
            URL
          }
        }
      }
    }
  `)

  const PageURL = data.glstrapi?.allMenus
    .filter(item => item?.URL === URL)
    .pop()

  if (!PageURL) return null

  let primary_URL =
    PageURL.main_parent === null &&
    PageURL.sub_parent === null &&
    URL === PageURL.URL &&
    !Secondary_URL
      ? PageURL.URL
      : false

  let secondary_URL =
    Secondary_URL === PageURL.Secondary_URL ? PageURL.Secondary_URL : false

  return (
    <>
      {primary_URL && (<Link to={`/${primary_URL}/`}>{label}</Link>)}
      {secondary_URL && (<a href={secondary_URL} target="_blank">{label}</a>)}
    </>
  )
}

export default GETURL
