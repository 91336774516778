import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';
import "animate.css/animate.css";
import $ from 'jquery'
const NewsSection = (props) => {

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    var SaleURL          = '/property/for-sale/'
    var RentURL          = '/property/to-rent/'

    var searcharea = capitalizeFirstLetter(props.area && props.area.replace(/-/g, ' '))

    if( props.area == "clapham-estate-agents" ) {
        searcharea = 'Clapham';
    }
    if( props.area == "battersea-estate-agents-and-nine-elms-estate-agents" ) {
        searcharea = 'Battersea';
    }

        let townURL = ''
        townURL = props?.area
        if(typeof townURL === "undefined" || townURL === null){
            townURL = 'london-and-manchester'
        } else {
            townURL = townURL.toLowerCase()
            townURL = townURL.replace(" ","-")
        }
    
    return (<React.Fragment>
        
        <Row>
            <Col md={6} lg={3}>
            <ul>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-${townURL}/`}>Properties for Sale in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-${townURL}/type-houses/`}>Houses for Sale in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-${townURL}/type-flats/`}>Flats for Sale in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-${townURL}/type-apartments/`}>Apartments for Sale in {searcharea}</Link></li>
            {props.guides && props.guides.map((guide) => {
                return<>
                <li><Link className="footer_scroll_top" to={`/about-us/areas-we-cover/${guide.Select_Areaguide_Location.toLowerCase()}-area-guides/${guide.URL}/`}>{guide.Title} Area Guide</Link></li>
                </>
            })}
            {props.guides && props.guides.length == 0 && props.guides=='' &&
            <li><Link className="footer_scroll_top" to="/about-us/areas-we-cover/london-area-guides/">London Area Guide</Link></li>
            }
            {props.office && props.office.map((office) => {
                var officename = ((office.Title && office.Title == 'Battersea & Nine Elm Office' ) ?  'Battersea' : office.Title);
                return<>
                <li><Link className="footer_scroll_top" to={`/contact/our-offices/${office.Select_Office_Location.toLowerCase()}-offices/${office.URL}/`}>Estate agents in {officename}</Link></li>
                </>
            })}
            {props.office && props.office.length == 0 && props.office=='' &&
            <li><Link className="footer_scroll_top" to="/contact/our-offices/">Estate agents in london</Link></li>
            }
            {props.area && props.area == "clapham-estate-agents" &&
            <>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw2/`}>Properties for sale in SW2</Link></li>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw4/`}>Properties for sale in SW4</Link></li>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw9/`}>Properties for sale in SW9</Link></li>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw12/`}>Properties for sale in SW12</Link></li>
            </>
            }
            {props.area && props.area == "battersea-estate-agents-and-nine-elms-estate-agents" &&
            <>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw11/`}>Properties for sale in SW11</Link></li>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw8/`}>Properties for sale in SW8</Link></li>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw11/type-houses/`}>Houses for sale in SW11</Link></li>
            <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw8/type-houses/`}>Houses for sale in SW8</Link></li>
            </>
            }
            </ul>
            </Col>
            <Col md={6} lg={3}>
            <ul>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-${townURL}/`}>Property to Rent in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-${townURL}/type-houses/`}>Houses to Rent in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-${townURL}/type-flats/`}>Flats to Rent in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-${townURL}/type-apartments/`}>Apartments to Rent in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to="/property-valuation/">Property Valuation</Link></li>
            {props.area && props.area == "clapham-estate-agents" &&
            <>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-sw2/`}>Properties to rent in SW2</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-sw4/`}>Properties to rent in SW4</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-sw9/`}>Properties to rent in SW9</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-sw12/`}>Properties to rent in SW12</Link></li>
            </>
            }
            {props.area && props.area == "battersea-estate-agents-and-nine-elms-estate-agents" &&
            <>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-sw11/`}>Properties to rent in SW11</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-sw8/`}>Properties to rent in SW8</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-sw11/type-houses/`}>Houses to rent in SW11</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-sw8/type-houses/`}>Houses to rent in SW8</Link></li>            
            <li><Link className="footer_scroll_top" to={`${RentURL}in-sw11/type-apartments/`}>Apartments to rent in SW11</Link></li>
            <li><Link className="footer_scroll_top" to={`${RentURL}in-sw8/type-apartments/`}>Apartments to rent in SW8</Link></li>
            </>
            }
            </ul>
            </Col>
            <Col md={6} lg={3}>
                <ul>
                {props.area && props.area == "clapham-estate-agents" &&
                <>
                <li><Link className="footer_scroll_top" to="/contact/our-offices/london-offices/">London Estate Agents</Link></li>
                <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw2/type-apartments/`}>Apartments for sale in SW2</Link></li>
                <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw4/type-apartments/`}>Apartments for sale in SW4</Link></li>
                <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw9/type-apartments/`}>Apartments for sale in SW9</Link></li>
                <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw12/type-apartments/`}>Apartments for sale in SW12</Link></li>
                <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw2/type-houses/`}>Houses for sale in SW2</Link></li>
                <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw4/type-houses/`}>Houses for sale in SW4</Link></li>
                <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw9/type-houses/`}>Houses for sale in SW9</Link></li>
                <li><Link className="footer_scroll_top" to={`${SaleURL}in-sw12/type-houses/`}>Houses for sale in SW12</Link></li>
                </>
            }
            {props.area && props.area == "battersea-estate-agents-and-nine-elms-estate-agents" &&
            <>
                <li><Link className="footer_scroll_top" to="/contact/our-offices/london-offices/">London Estate Agents</Link></li>
                <li><Link className="footer_scroll_top" to="/contact/our-offices/manchester-offices/">Manchester Letting Agents</Link></li>
                <li><Link className="footer_scroll_top" to="/contact/our-offices/london-offices/clapham-estate-agents/">Clapham Estate Agents</Link></li>
                <li><Link className="footer_scroll_top" to="/property/new-homes/for-sale/in-london/">New Homes for Sale in London</Link></li>
                <li><Link className="footer_scroll_top" to="/property-services/property-management/">London Property Management</Link></li>
                <li><Link className="footer_scroll_top" to="/about-us/areas-we-cover/london-area-guides/">London Area Guides</Link></li>
                <li><Link className="footer_scroll_top" to="/about-us/careers/">Estate Agent Jobs in London</Link></li>
                <li><Link className="footer_scroll_top" to="/about-us/stories/">London Property News</Link></li>
                <li><Link className="footer_scroll_top" to="/property/for-sale/in-Nine-Elms/">Properties for sale in Nine Elms</Link></li>
                </>
            }
            </ul>
            </Col>
            <Col md={6} lg={3}>
                <ul>
                {props.area && props.area == "clapham-estate-agents" &&
                 <>
                <li><Link className="footer_scroll_top" to="/contact/our-offices/london-offices/battersea-estate-agents-and-nine-elms-estate-agents/">Battersea Estate Agents</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-sw2/type-apartments/`}>Apartments to rent in SW2</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-sw4/type-apartments/`}>Apartments to rent in SW4</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-sw9/type-apartments/`}>Apartments to rent in SW9</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-sw12/type-apartments/`}>Apartments to rent in SW12</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-sw2/type-houses/`}>Houses to rent in SW2</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-sw4/type-houses/`}>Houses to rent in SW4</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-sw9/type-houses/`}>Houses to rent in SW9</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-sw12/type-houses/`}>Houses to rent in SW12</Link></li>
                </>
            }
            {props.area && props.area == "battersea-estate-agents-and-nine-elms-estate-agents" &&
            <>
                <li><Link className="footer_scroll_top" to="/property-services/">Property Services</Link></li>
                <li><Link className="footer_scroll_top" to="/contact/our-offices/manchester-offices/manchester-property-investments/">Manchester Property Investments</Link></li>
                <li><Link className="footer_scroll_top" to="/marketing-your-property/">Marketing your Property</Link></li>
                <li><Link className="footer_scroll_top" to="/property-services/landlords/">Landlords Services</Link></li>
                <li><Link className="footer_scroll_top" to="/property-services/buy/mortgages/">Mortgages</Link></li>
                <li><Link className="footer_scroll_top" to="/property-services/auctions/">Auctions</Link></li>
                <li><Link className="footer_scroll_top" to="/property/to-rent/in-Nine-Elms/">Properties to rent in Nine Elms</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-sw8/type-apartments/`}>Apartments to rent in Nine Elms</Link></li>
                <li><Link className="footer_scroll_top" to={`${RentURL}in-sw8/type-houses/`}>Houses to rent in Nine Elms</Link></li>
                </>
            }
                </ul>
            </Col>
        </Row>
  
    </React.Fragment>)

}

export default NewsSection;
