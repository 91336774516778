import React, { useState,useEffect } from "react";
import { Link } from "gatsby";
import { Row, Col } from 'react-bootstrap';
import "animate.css/animate.css";
import $ from 'jquery'

const NewsSection = (props) => {
  //Get values from refine search
	const [ptype,setPtype] = useState(false)
  const [searcharea,setsearchArea] = useState(false)
  const [searchbed,setsearchbed] = useState(false)

  useEffect(() => {
    var checkptype = $('.slct-prprty option:selected').val();    
    setPtype(checkptype)
    var checkbed = $('.slct-room option:selected').val();    
    setsearchbed(checkbed)
  });
  if (typeof window !== 'undefined') {
    $( ".filter-form #formBasicEmail" ).change(function(){
      setsearchArea($(this).val().toLowerCase())
    });
    
    $(".slct-prprty select").change(function(){
      setPtype($(this).val())
    });
    $(".slct-room select").change(function(){
      setsearchbed($(this).val())
    });
  }
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } 
  var searchaction = ''
  var URL = ''
  var ptypeURL = ''
  var bedURL = ''
  var bedtypeText= ''
  var area = props.areaName ? capitalizeFirstLetter(props.areaName) : 'London'
  var areaURL = props?.areaName?.toLowerCase()
  var typeText = 'Properties'
  
  if(props.searchtype == 'sales') {
    searchaction = 'for Sale'
    URL          = '/property/for-sale/'
  }
  if(props.searchtype == 'rentals') {
    searchaction = 'to Rent'
    URL          = '/property/to-rent/'
  }
  if(props.searchtype == 'newhomesales') {
    searchaction = 'for Sale'
    typeText = 'New Homes'
    URL          = '/property/new-homes/for-sale/'
  }
  if(props.searchtype == 'newhomerentals') {
    searchaction = 'to Rent'
    typeText = 'New Homes'
    URL          = '/property/new-homes/to-rent/'
  }
  if(props.searchtype == 'newdevelopmentsales') {
    searchaction = 'for Sale'
    typeText = 'New Homes'
    //URL          = '/property/new-developments/for-sale/'
    URL          = '/property/developments/new-homes/for-sale/'
  }
  if(props.searchtype == 'newdevelopmentrentals') {
    searchaction = 'to Rent'
    typeText = 'New Homes'
    //URL          = '/property/new-developments/to-rent/'
    URL          = '/property/developments/new-homes/to-rent/'
  }

  if(props.searchtype == 'helptobuysales') {
    searchaction = 'for Sale'
    URL          = '/property/help-to-buy/'
  }
  if(ptype) {
    ptypeURL   = '/type-'+ptype.toLowerCase()
    typeText     = capitalizeFirstLetter(ptype)
  }
  if(searchbed) {
    bedtypeText = searchbed+' Bedroom '+typeText
    bedURL   = ('/'+searchbed+'-and-more-bedrooms').toLowerCase()
  }
 // console.log("setsearchArea",setsearchArea);
  return (<React.Fragment>
        <Row>
          {searchbed ?
          <>
            <Col md={6} lg={3}>            
              <ul>
              { props.searchtype && 
              (props.searchtype.match(/^(newhomesales|newdevelopmentsales|newhomerentals|newdevelopmentrentals)$/)) &&
              <li><Link className="footer_scroll_top" to={`${URL}in-manchester${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Manchester</Link></li>
              }
                <li><Link className="footer_scroll_top" to={`${URL}in-london${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in London</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-battersea${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Battersea</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-clapham${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Clapham</Link></li>
              </ul>
            </Col>       
            <Col md={6} lg={3}>
              <ul>
                <li><Link className="footer_scroll_top" to={`${URL}in-brixton${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Brixton</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-balham${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Balham</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-nine-elms${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Nine Elms</Link></li>           
              </ul>
            </Col>
          </>
          :<>
            <Col md={6} lg={3}>
              <ul>
              { props.searchtype && 
              (props.searchtype.match(/^(newhomesales|newdevelopmentsales|newhomerentals|newdevelopmentrentals)$/)) &&
              <li><Link className="footer_scroll_top" to={`${URL}in-manchester${ptypeURL}/`}>{typeText} {searchaction} in Manchester</Link></li>
              }

                <li><Link className="footer_scroll_top" to={`${URL}in-london${ptypeURL}/`}>{typeText} {searchaction} in London</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-battersea${ptypeURL}/`}>{typeText} {searchaction} in Battersea</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-clapham${ptypeURL}/`}>{typeText} {searchaction} in Clapham</Link></li>
                </ul>
            </Col>
            <Col md={6} lg={3}>
              <ul>
                <li><Link className="footer_scroll_top" to={`${URL}in-brixton${ptypeURL}/`}>{typeText} {searchaction} in Brixton</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-balham${ptypeURL}/`}>{typeText} {searchaction} in Balham</Link></li>
                <li><Link className="footer_scroll_top" to={`${URL}in-nine-elms${ptypeURL}/`}>{typeText} {searchaction} in Nine Elms</Link></li>           
              </ul>
            </Col>
          </>
          }
          {searcharea ?
          <>
          <Col md={6} lg={3}>
          <ul>
           {ptype && searchbed && 
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/`}>Properties {searchaction} in {searcharea}</Link></li>
            }
            {ptype && searcharea && 
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/`}>Properties {searchaction} in {searcharea}</Link></li>
            }
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-houses/`}>Houses {searchaction} in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-flats/`}>Flats {searchaction} in {searcharea}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${searcharea}/type-apartments/`}>Apartment {searchaction} in {searcharea}</Link></li>
           </ul>
           </Col>
           <Col md={6} lg={3}>
            <ul>
             { props.searchtype &&  (
              (props.searchtype.match(/^(newhomesales|newdevelopmentsales|newhomerentals|newdevelopmentrentals)$/)) ?
                    <>
                      <li><Link className="footer_scroll_top" to="/contact/our-offices/manchester-offices/">Manchester Letting Agents</Link></li>
                      <li><Link className="footer_scroll_top" to="/contact/our-offices/manchester-offices/manchester-property-investments/">Manchester Property Investments</Link></li>
                    </>
                    :
                    <>
                    <li><Link className="footer_scroll_top" to="/about-us/areas-we-cover/london-area-guides/">London Area Guides</Link></li>
                    <li><Link className="footer_scroll_top" to="/property-valuation/">Property Valuation London</Link></li>
                    <li><Link className="footer_scroll_top" to="/contact/our-offices/">London Estate Agents</Link></li> 
                    </>
              )
            }
          </ul>
          </Col>
          </>
          :
          <>
          <Col md={6} lg={3}>
          <ul>
            <li><Link className="footer_scroll_top"  to={`${URL}in-${areaURL}/type-houses/`}>Houses {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${areaURL}/type-flats/`}>Flats {searchaction} in {area}</Link></li>
            <li><Link className="footer_scroll_top" to={`${URL}in-${areaURL}/type-apartments/`}>Apartment {searchaction} in {area}</Link></li>
          </ul>
          </Col> 
          <Col md={6} lg={3}>
          <ul>
          { props.searchtype &&  (              
              (props.searchtype.match(/^(newhomesales|newdevelopmentsales|newhomerentals|newdevelopmentrentals)$/)) ?
                    <>
                      <li><Link className="footer_scroll_top" to="/contact/our-offices/manchester-offices/">Manchester Letting Agents</Link></li>
                      <li><Link className="footer_scroll_top" to="/contact/our-offices/manchester-offices/manchester-property-investments/">Manchester Property Investments</Link></li>
                    </>
                    :
                    <>
                    <li><Link className="footer_scroll_top" to="/about-us/areas-we-cover/london-area-guides/">London Area Guides</Link></li>
                    <li><Link className="footer_scroll_top" to="/property-valuation/">Property Valuation London</Link></li>
                    <li><Link className="footer_scroll_top" to="/contact/our-offices/">London Estate Agents</Link></li> 
                    </>
              )
            }
          </ul>
          </Col> 
          </> 
          }       
        </Row>



  </React.Fragment>)

}

export default NewsSection;