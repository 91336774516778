import React from "react";
import { Link } from "gatsby";
import "./StickyCta.scss";
import callIcon from "../../images/float-call-icon.svg";
const StickyCtaSection = (props) =>{
    var phonenumberurl = props.phonenumberlink && props.phonenumberlink  ? "01616760099" : "02076273197";
    return(
        <>
          <div className="footer-sticky-wrapper">
            <a href={`tel:${phonenumberurl}`} className="sticky-btn contact">  
                <img src={callIcon} className="floating-phone" /> 
                </a> 
              <Link to={"/property-valuation/"} className={`sticky-btn valuation`}>Get a free valuation</Link>
            
          </div>
       </>
    ); 
}
export default StickyCtaSection;
